import { Header, Reward20200901 } from 'components/policy';

const PolicyReward = () => {
  return (
    <>
      <Header />
      <Reward20200901 />
    </>
  );
};

export default PolicyReward;
